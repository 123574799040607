import { showNotification } from '@mantine/notifications';

const TTL = 5 * 1000;

function notify(title: string, message?: string, isError?: boolean) {
    showNotification({
        title,
        message,
        color: isError ? 'red' : 'blue',
        autoClose: TTL,
    });
}

export default notify;
