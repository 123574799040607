import { Bet, Room, User } from '../../../engine/model';
import notify from '../../../utils/notifications';
import { addEventHandler, ErrorPayload, ResponseMessage } from './client';
import { stores } from '../../../stores/stores';
import i18n from '../../../locale/i18n';

const { roomStore, chatStore } = stores;
// eslint-disable-next-line react-hooks/rules-of-hooks
const t = i18n.t;

const onUpdate = (response: ResponseMessage) => {
    if (!response.success) {
        const { summary } = response.payload as ErrorPayload;
        notify(t('error_get_room_state'), summary, true);
    }

    const { state } = response.payload as { state: Room };
    roomStore.pin = state.pin;
    roomStore.state = state.state;
    roomStore.slots = state.slots;
    roomStore.isChecking = state.isChecking;
    roomStore.isMaputa = state.isMaputa;
};

const onEnter = (response: ResponseMessage) => {
    const { user } = response.payload as { user: User };
    chatStore.addMessage(
        t('room_log_user_connected', { user_name: user.name })
    );
};

const onLeave = (response: ResponseMessage) => {
    const { user } = response.payload as { user: User };
    chatStore.addMessage(t('room_log_user_left', { user_name: user.name }));
};

const onTakeSlot = (response: ResponseMessage) => {
    const { user } = response.payload as { user: User };
    chatStore.addMessage(
        t('room_log_user_taken_slot', { user_name: user.name })
    );
};

const onLeaveSlot = (response: ResponseMessage) => {
    const { user } = response.payload as { user: User };
    chatStore.addMessage(
        t('room_log_user_left_slot', { user_name: user.name })
    );
};

const onKick = (response: ResponseMessage) => {
    const { user } = response.payload as { user: User };
    chatStore.addMessage(t('room_log_user_kicked', { user_name: user.name }));
};

const onStart = () => {
    chatStore.addMessage(t('room_log_game_started'));
};

const onStartRound = () => {
    chatStore.addMessage(t('room_log_round_started'));
};

const onTurn = (response: ResponseMessage) => {
    const { user } = response.payload as { user: User };
    chatStore.addMessage(t('room_log_turn', { user_name: user.name }));
};

const onBet = (response: ResponseMessage) => {
    const { user, bet } = response.payload as { user: User; bet: Bet };
    chatStore.addMessage(
        t('room_log_bet', {
            user_name: user.name,
            count: bet.count,
            bet_nominal: bet.nominal,
        })
    );
};

const onCheck = (response: ResponseMessage) => {
    const { user } = response.payload as { user: User };
    chatStore.addMessage(t('room_log_check', { user_name: user.name }));
};

const onCheckFail = () => {
    chatStore.addMessage(t('room_log_check_fail'));
};

const onCheckSuccess = () => {
    chatStore.addMessage(t('room_log_check_success'));
};

const onDieDrop = (response: ResponseMessage) => {
    const { user } = response.payload as { user: User };
    chatStore.addMessage(t('room_log_die_drop', { user_name: user.name }));
};

const onEndRound = () => {
    chatStore.addMessage(t('room_log_round_ended'));
};

const onEndGame = (response: ResponseMessage) => {
    if (response.payload) {
        const { winner } = response.payload as { winner?: User };

        if (winner) {
            return chatStore.addMessage(
                t('room_log_winner', { user_name: winner.name })
            );
        }
    }

    return chatStore.addMessage(t('room_log_winner_undefined'));
};

export function addEventHandlers(): void {
    addEventHandler('update', onUpdate);
    addEventHandler('enter', onEnter);
    addEventHandler('leave', onLeave);
    addEventHandler('take-slot', onTakeSlot);
    addEventHandler('leave-slot', onLeaveSlot);
    addEventHandler('kick', onKick);
    addEventHandler('start', onStart);
    addEventHandler('start-round', onStartRound);
    addEventHandler('turn', onTurn);
    addEventHandler('bet', onBet);
    addEventHandler('check', onCheck);
    addEventHandler('check-fail', onCheckFail);
    addEventHandler('check-success', onCheckSuccess);
    addEventHandler('die-drop', onDieDrop);
    addEventHandler('end-round', onEndRound);
    addEventHandler('end-game', onEndGame);
}
