import React from 'react';
import { Flex, Group, Text } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Bet as BetProps } from '../../../engine/model';
import Die from './Die';

const Bet: React.FC<BetProps> = ({ count, nominal }) => {
    const { t } = useTranslation();

    return (
        <Group spacing='md'>
            <Text>{t('room_slot_bet')}</Text>
            <Flex justify='flex-start' align='center' direction='row' gap='xs'>
                <Text>{count}</Text>
                <Text>x</Text>
                <Die key={Math.random()} value={nominal} />
            </Flex>
        </Group>
    );
};

export default observer(Bet);
