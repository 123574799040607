import React, { useCallback } from 'react';
import { Button, Card, Grid } from '@mantine/core';
import { BsFillArrowDownSquareFill } from 'react-icons/bs';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Bet as IBet, Slot as SlotInfo } from '../../../engine/model';
import * as _network from '../../../network/network';
import Dice from './Dice';
import Bet from './Bet';
import SlotTitle from './SlotTitle';
import BetEditor from './BetEditor';
import { useStore } from '../../../stores/stores';

interface SlotProps {
    slot: SlotInfo;
    canBet: boolean;
    canCheck: boolean;
    canDisband: boolean;
}

const Slot: React.FC<SlotProps> = ({ slot, canBet, canCheck, canDisband }) => {
    const { userStore } = useStore();
    const { t } = useTranslation();

    const leaveSlot = useCallback(async () => {
        _network.leaveSeat();
    }, []);

    const kick = useCallback(async () => {
        _network.kick(slot.user.id);
    }, [slot.user.id]);

    const bet = useCallback(async (bet: IBet) => {
        _network.bet(bet);
    }, []);

    const check = useCallback(async () => {
        _network.check();
    }, []);

    const dropDie = useCallback(() => {
        _network.dropDie();
    }, []);

    return (
        <Card
            p='xs'
            px='md'
            sx={(theme) => {
                let color = undefined;
                if (slot.state === 'current' || slot.state === 'dropping')
                    color = theme.colors.dark[4];

                return {
                    borderColor: color,
                    background: color,
                };
            }}
        >
            <Grid align='center'>
                <Grid.Col span={12}>
                    <SlotTitle
                        name={slot.user.name}
                        state={slot.state}
                        disbandCallback={
                            canDisband
                                ? userStore.id === slot.user.id
                                    ? leaveSlot
                                    : kick
                                : undefined
                        }
                    />
                </Grid.Col>
                {slot.dice && slot.dice.length > 0 && (
                    <Grid.Col md={12} lg={3}>
                        <Dice dice={slot.dice} />
                    </Grid.Col>
                )}
                {slot.bet && (
                    <Grid.Col md={12} lg={3}>
                        <Bet
                            nominal={slot.bet.nominal}
                            count={slot.bet.count}
                        />
                    </Grid.Col>
                )}
                {(slot.state === 'current' || slot.state === 'dropping') &&
                    userStore.id === slot.user.id &&
                    (slot.state === 'dropping' ? (
                        <Grid.Col span='auto'>
                            <Button
                                leftIcon={<BsFillArrowDownSquareFill />}
                                onClick={dropDie}
                            >
                                {t('room_slot_action_drop')}
                            </Button>
                        </Grid.Col>
                    ) : (
                        canBet && (
                            <Grid.Col span='auto'>
                                <BetEditor
                                    betHandler={bet}
                                    checkHandler={canCheck ? check : undefined}
                                />
                            </Grid.Col>
                        )
                    ))}
            </Grid>
        </Card>
    );
};

export default observer(Slot);
