import axios from 'axios';
import * as _config from '../../../utils/config';

const restClient = axios.create({
    baseURL: _config.REST_URL,
    timeout: 10000,
    withCredentials: true,
});

restClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 403) {
            document.location = '/';
            throw error;
        }
    }
);

export default restClient;
