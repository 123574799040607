import React from 'react';
import { observer } from 'mobx-react-lite';
import { Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../stores/stores';
import Slot from './slot/Slot';

const SlotContainer: React.FC = () => {
    const { userStore, roomStore } = useStore();
    const { t } = useTranslation();

    if (!roomStore.pin || !userStore.id) return <></>;

    if (
        roomStore.slots &&
        roomStore.slots.filter((slot) => slot.state !== 'spectator').length ===
            0
    )
        return <Text>{t('room_slots_empty')}</Text>;

    const isHost = roomStore.getSlot(userStore.id)?.isHost;
    return (
        <Stack spacing='lg'>
            {roomStore.slots &&
                roomStore.slots
                    .filter((slot) => slot.state !== 'spectator')
                    .map((slot) => {
                        const isYou = slot.user.id === userStore.id;

                        const canBet = isYou && slot.state === 'current';
                        const canCheck =
                            isYou &&
                            slot.state === 'current' &&
                            !!roomStore.slots?.find(
                                (slot) => slot.bet !== undefined
                            );
                        const canDisband = isYou || !!isHost;

                        return (
                            <Slot
                                key={slot.user.id}
                                slot={slot}
                                canBet={canBet}
                                canCheck={canCheck}
                                canDisband={canDisband}
                            />
                        );
                    })}
        </Stack>
    );
};

export default observer(SlotContainer);
