import { io, Socket } from 'socket.io-client';
import { stores } from '../../../stores/stores';
import * as _config from '../../../utils/config';
import notify from '../../../utils/notifications';
import { enter } from '../../network';
import { addEventHandlers } from './handlers';
import RoomCommand from './RoomCommand';
import RoomEvent from './RoomEvent';

const socket = io(_config.WS_URL, {
    reconnectionAttempts: Infinity,
    timeout: 10000,
    transports: ['websocket', 'polling'],
});

// transport settings
export function connect() {
    socket.connect();
}

export function disconnect() {
    if (socket.connected) socket.disconnect();
}

const onDisconnect = (reason: Socket.DisconnectReason) => {
    if (
        !(
            reason === 'io server disconnect' ||
            reason === 'io client disconnect'
        )
    ) {
        notify(
            'Connection lost',
            `We will try to reconnect automatically.
            If the problem persists, check your internet connection,
            reload the page or try again later.`,
            true
        );
        // stores.roomStore.erase();
    } else {
        if (reason === 'io server disconnect') {
            notify(
                'Disconnected from the server',
                'Seems the server has shut down. Please try logging in later.',
                true
            );
        } else connect();
    }
};

const onConnectError = () => {
    // revert to classic upgrade
    socket.io.opts.transports = ['polling', 'websocket'];
};

const onReconnect = () => {
    notify('Reconnected successfully', undefined, true);

    if (stores.roomStore.pin) {
        enter(stores.roomStore.pin);
    }
};

const onError = (response: ResponseMessage | undefined) => {
    if (!response || !response.payload)
        notify('Server error', 'IDK what just has happened', true);
    else {
        const { summary, detail } = response.payload as ErrorPayload;
        notify(summary, detail, true);
    }
};

socket.on('disconnect', onDisconnect);
socket.on('connect_error', onConnectError);
socket.on('reconnect', onReconnect);
socket.on('error', onError);

connect();
addEventHandlers();

// client-emitted commands

/**
 * Sends a command to the server.
 */
export function sendCommand(command: RoomCommand, payload?: unknown) {
    socket.emit(command, payload);
}

//room event handlers
export type ResponseMessage = {
    success: boolean;
    payload?: unknown;
};

export type ErrorPayload = {
    summary: string;
    detail?: string;
};

export function addEventHandler(
    event: RoomEvent,
    handler: (response: ResponseMessage) => void | (() => void)
) {
    socket.on(event, (response) => {
        return handler(response);
    });
}

export function removeEventHandler(
    event: RoomEvent,
    handler: (response: ResponseMessage) => void
) {
    socket.off(event, handler);
}
