import React, { useState } from 'react';
import { Text } from '@mantine/core';
import { observer } from 'mobx-react-lite';

const AppVersion: React.FC = () => {
    const [version] = useState(process.env.REACT_APP_VERSION || 'dev');

    return <Text>v.{version}</Text>;
};

export default observer(AppVersion);
