import React from 'react';
import { Flex, Group } from '@mantine/core';
import { observer } from 'mobx-react-lite';

import Die from './Die';

interface DiceProps {
    dice?: number[];
}

const Dice: React.FC<DiceProps> = ({ dice }) => {
    if (!dice || dice.length === 0) return <></>;

    return (
        <Group>
            <Flex justify='flex-start' align='top' direction='row' gap='sm'>
                {dice.map((d) => (
                    <Die key={Math.random()} value={d} />
                ))}
            </Flex>
        </Group>
    );
};

export default observer(Dice);
