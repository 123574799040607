import { makeAutoObservable } from 'mobx';

class UserStore {
    private _id?: string | undefined;
    private _name?: string | undefined;

    constructor() {
        const savedName = localStorage.getItem('name');
        if (savedName) {
            this.name = savedName;
        } else this.name = undefined;

        const savedId = localStorage.getItem('id');
        if (savedId) {
            this.id = savedId;
        } else this.id = undefined;

        makeAutoObservable(this);
    }

    public get id(): string | undefined {
        return this._id;
    }

    public set id(value: string | undefined) {
        this._id = value;

        if (value) localStorage.setItem('id', value);
        else localStorage.removeItem('id');
    }

    public get name(): string | undefined {
        return this._name;
    }

    public set name(value: string | undefined) {
        this._name = value;

        if (value) localStorage.setItem('name', value);
        else localStorage.removeItem('name');
    }
}

export default UserStore;
