import React, { useCallback, useEffect, useRef } from 'react';
import { Card, Flex, ScrollArea, Stack, Text, Title } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../stores/stores';

const Chat: React.FC = () => {
    const { chatStore } = useStore();
    const { t } = useTranslation();

    const viewport = useRef<HTMLDivElement>(null);
    const scrollToBottom = useCallback(() => {
        if (viewport && viewport.current)
            viewport.current.scrollTo({
                top: viewport.current.scrollHeight,
                behavior: 'smooth',
            });
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [scrollToBottom, chatStore.messages.length]);

    return (
        <Card h='100%' mah='70vh' py='xs'>
            <Stack h='100%'>
                <Title order={3}>{t('room_log_title')}</Title>
                <ScrollArea
                    type='auto'
                    offsetScrollbars
                    viewportRef={viewport}
                    w='100%'
                    h='100%'
                >
                    <Stack spacing='xs'>
                        {chatStore.messages.map((message) => (
                            <Flex
                                justify='flex-start'
                                align='flex-start'
                                gap='xs'
                                key={Math.random()}
                            >
                                <Text fw={500}>{`[${message.timestamp.format(
                                    'HH:mm:ss'
                                )}]`}</Text>
                                <Text>{message.text}</Text>
                            </Flex>
                        ))}
                    </Stack>
                </ScrollArea>
            </Stack>
        </Card>
    );
};

export default observer(Chat);
