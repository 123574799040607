import { makeAutoObservable } from 'mobx';
import moment from 'moment';

export interface ChatMessage {
    timestamp: moment.Moment;
    text: string;
}

class ChatStore {
    private _messages: ChatMessage[];

    constructor() {
        this._messages = [];
        makeAutoObservable(this);
    }

    public get messages(): ChatMessage[] {
        return this._messages;
    }

    public addMessage(text: string): void {
        this._messages.push({ timestamp: moment(), text } as ChatMessage);
    }

    public clear(): void {
        this._messages = [];
    }
}

export default ChatStore;
