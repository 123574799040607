import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Flex,
    Group,
    NumberInput,
    SegmentedControl,
    SegmentedControlItem,
    Text,
} from '@mantine/core';
import { BsXCircle, BsCheckCircle } from 'react-icons/bs';
import { GiTwoCoins, GiAngryEyes } from 'react-icons/gi';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Die from './Die';
import { Bet } from '../../../engine/model';
import { useStore } from '../../../stores/stores';
import TooltipButton from '../../TooltipButton';

interface BetEditorProps {
    betHandler: (bet: Bet) => void;
    checkHandler?: () => void;
}

const initialNominals = [
    { value: '1', label: <Die value={1} /> },
    { value: '2', label: <Die value={2} /> },
    { value: '3', label: <Die value={3} /> },
    { value: '4', label: <Die value={4} /> },
    { value: '5', label: <Die value={5} /> },
    { value: '6', label: <Die value={6} /> },
];

const BetEditor: React.FC<BetEditorProps> = ({ betHandler, checkHandler }) => {
    const { roomStore } = useStore();
    const [isBetting, setBetting] = useState(false);
    const [nominals, setNominals] =
        useState<SegmentedControlItem[]>(initialNominals);
    const [minCount, setMinCount] = useState(1);
    const [maxCount, setMaxCount] = useState(1);
    const [isUnbettable, setUnbettable] = useState(false);
    const [nominal, setNominal] = useState<string>(nominals[0].value);
    const [count, setCount] = useState<number | undefined>(1);
    const { t } = useTranslation();

    useEffect(() => {
        if (!roomStore.slots) return;

        const max = roomStore.slots
            .filter((slot) => slot.state !== 'spectator')
            .reduce(
                (count, slot) => count + (slot.dice ? slot.dice.length : 0),
                0
            );
        setMaxCount(max);

        let min = 1;
        let allowedNominals = initialNominals;

        const previousBet = roomStore.getPreviousBet();
        if (previousBet) {
            min = previousBet.count;

            if (roomStore.isMaputa) {
                allowedNominals = initialNominals.filter(
                    (nominal) =>
                        Number.parseInt(nominal.value) === previousBet.nominal
                );
            }
        }

        setNominals(allowedNominals);
        setNominal(allowedNominals[0].value);
        setMinCount(min);
        setCount(min);
        if (min > max) {
            setUnbettable(true);
        }
    }, [roomStore, roomStore.slots]);

    const handleActionToggle = useCallback(
        () => setBetting((prev) => !prev),
        []
    );

    const handleBet = useCallback(() => {
        if (!isUnbettable && count)
            betHandler({ nominal: Number.parseInt(nominal), count: count });
    }, [betHandler, count, isUnbettable, nominal]);

    return (
        <Group spacing='md'>
            <Text>{t('room_slot_action')}</Text>
            {!isBetting ? (
                <Group spacing='sm'>
                    <Button
                        variant='outline'
                        leftIcon={<GiTwoCoins />}
                        onClick={handleActionToggle}
                    >
                        {t('room_slot_action_bet')}
                    </Button>
                    {checkHandler && (
                        <Button
                            variant='outline'
                            leftIcon={<GiAngryEyes />}
                            onClick={checkHandler}
                        >
                            {t('room_slot_action_check')}
                        </Button>
                    )}
                </Group>
            ) : (
                <Flex
                    justify='flex-start'
                    align='center'
                    direction='row'
                    gap='md'
                    wrap='wrap'
                >
                    {nominals.length >= 1 && (
                        <Group spacing='sm'>
                            <NumberInput
                                w={60}
                                disabled={isUnbettable}
                                min={!isUnbettable ? minCount : undefined}
                                max={!isUnbettable ? maxCount : undefined}
                                step={1}
                                value={count}
                                precision={0}
                                onChange={(val) => setCount(val)}
                            />
                            <Text>x</Text>
                            <SegmentedControl
                                size='xs'
                                data={nominals}
                                disabled={isUnbettable}
                                onChange={(val) => setNominal(val)}
                            />
                        </Group>
                    )}
                    <Group spacing='sm'>
                        <TooltipButton
                            label={t('room_slot_action_cancel')}
                            variant='outline'
                            onClick={handleActionToggle}
                            icon={<BsXCircle />}
                        />
                        <TooltipButton
                            label={t('room_slot_action_confirm')}
                            variant='filled'
                            color='blue'
                            onClick={handleBet}
                            icon={<BsCheckCircle />}
                        />
                    </Group>
                </Flex>
            )}
        </Group>
    );
};

export default observer(BetEditor);
