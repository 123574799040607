import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NotificationsProvider } from '@mantine/notifications';
import { observer } from 'mobx-react-lite';
import './locale/i18n';

import RoomPage from './components/RoomPage';
import LoginPage from './components/LoginPage';

const App: React.FC = () => {
    return (
        <NotificationsProvider position='bottom-right' zIndex={2077} limit={5}>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<LoginPage />} />
                    <Route path='/room/:pin' element={<RoomPage />} />
                </Routes>
            </BrowserRouter>
        </NotificationsProvider>
    );
};

export default observer(App);
