import React, { useState } from 'react';
import { List, Modal, Stack, Text, Title } from '@mantine/core';
import { BsQuestion } from 'react-icons/bs';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import TooltipButton from './TooltipButton';

const Rules: React.FC = () => {
    const [opened, setOpened] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <TooltipButton
                variant='light'
                onClick={() => setOpened(true)}
                icon={<BsQuestion />}
                label={t('rules_tooltip')}
            />

            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title={t('rules_tooltip')}
                centered
                size='xl'
            >
                <Stack>
                    <Title order={3}>{t('rules_game_flow_title')}</Title>
                    <Text size='md'>{t('rules_game_flow')}</Text>
                    <List withPadding>
                        <List.Item>{t('rules_game_flow_a')}</List.Item>
                        <List.Item>{t('rules_game_flow_b')}</List.Item>
                        <List.Item>{t('rules_game_flow_c')}</List.Item>
                        <List.Item>{t('rules_game_flow_d')}</List.Item>
                    </List>
                    <Title order={3}>{t('rules_one_title')}</Title>
                    <Text size='md'>{t('rules_one')}</Text>
                    <Title order={3}>{t('rules_wagering_title')}</Title>
                    <Text size='md'>{t('rules_wagering_a')}</Text>
                    <Text size='md'>{t('rules_wagering_b')}</Text>
                    <Title order={3}>{t('rules_maputa_title')}</Title>
                    <Text size='md'>{t('rules_maputa')}</Text>
                </Stack>
            </Modal>
        </>
    );
};

export default observer(Rules);
