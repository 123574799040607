import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { Anchor, Grid, Stack, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useStore } from '../stores/stores';
import * as _network from '../network/network';
import PageLayout from './PageLayout';
import Chat from './room/Chat';
import RoomPageControls from './room/RoomPageControls';
import SlotContainer from './room/SlotContainer';

const RoomPage: React.FC = () => {
    const navigate = useNavigate();
    const { pin } = useParams() as { pin: string };
    const { roomStore } = useStore();
    const [isLoading, setLoading] = useState<boolean>(true);
    const { t } = useTranslation();

    const joinRoom = useCallback(async () => {
        _network.enter(pin);
    }, [pin]);

    const goBack = useCallback(() => {
        _network.leave();
        roomStore.erase();
        navigate('/');
    }, [navigate, roomStore]);

    useEffect(() => {
        setLoading(true);
        void joinRoom();
        _network.getInfo();
        setLoading(false);
    }, [joinRoom]);

    return (
        <PageLayout isLoading={isLoading}>
            <Stack align='stretch' justify='flex-start'>
                <RoomPageControls />
                {!isLoading && !roomStore.pin ? (
                    <Stack>
                        <Title order={2}>{t('room_error_title')}</Title>
                        <Text>
                            {t('room_error_description_a') + ' '}
                            <Anchor onClick={goBack}>
                                {t('room_error_description_b')}
                            </Anchor>
                            {' ' + t('room_error_description_c')}
                        </Text>
                    </Stack>
                ) : (
                    <Grid justify='flex-start' align='stretch' columns={12}>
                        <Grid.Col md={12} lg={8}>
                            <SlotContainer />
                        </Grid.Col>
                        <Grid.Col md={12} lg={4}>
                            <Chat />
                        </Grid.Col>
                    </Grid>
                )}
            </Stack>
        </PageLayout>
    );
};

export default observer(RoomPage);
