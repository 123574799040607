import { makeAutoObservable } from 'mobx';
import { Bet, User, Slot, RoomState } from '../engine/model';

class RoomStore {
    private _pin?: string | undefined;
    private _state?: RoomState | undefined;
    private _slots?: Slot[] | undefined;
    private _isChecking?: boolean | undefined;
    private _isMaputa?: boolean | undefined;

    constructor() {
        this.pin = undefined;
        this.state = undefined;
        this.slots = undefined;
        this.isChecking = undefined;
        this.isMaputa = undefined;

        makeAutoObservable(this);
    }

    public get pin(): string | undefined {
        return this._pin;
    }

    public set pin(value: string | undefined) {
        this._pin = value;
    }

    public get state(): RoomState | undefined {
        return this._state;
    }

    public set state(value: RoomState | undefined) {
        this._state = value;
    }

    public get slots(): Slot[] | undefined {
        return this._slots;
    }

    public set slots(value: Slot[] | undefined) {
        this._slots = value;
    }

    public get isChecking(): boolean | undefined {
        return this._isChecking;
    }

    public set isChecking(value: boolean | undefined) {
        this._isChecking = value;
    }

    public get isMaputa(): boolean | undefined {
        return this._isMaputa;
    }

    public set isMaputa(value: boolean | undefined) {
        this._isMaputa = value;
    }

    public erase() {
        this.pin = undefined;
        this.state = undefined;
        this.slots = undefined;
        this.isChecking = undefined;
        this.isMaputa = undefined;
    }

    public getHost(): User | undefined {
        if (this.slots) return this.slots.find((slot) => slot.isHost)?.user;

        return undefined;
    }

    public getSlot(userId: string): Slot | undefined {
        if (this.slots)
            return this.slots.find((slot) => slot.user.id === userId);

        return undefined;
    }

    public getPlayerSlots(): Slot[] {
        if (this.slots)
            return this.slots.filter((slot) => slot.state !== 'spectator');

        return [];
    }

    private getCurrentSlot(): Slot | undefined {
        if (!this.slots) return undefined;
        return this.slots.find(
            (slot) => slot.state === 'current' || slot.state === 'dropping'
        );
    }

    private getPreviousSlot(
        current: Slot,
        onlyNotLost?: boolean
    ): Slot | undefined {
        if (!this.slots) return undefined;

        const currentIndex = this.slots.indexOf(current);
        if (currentIndex === -1) return undefined;

        let previousIndex =
            (currentIndex - 1 + this.slots.length) % this.slots.length;
        if (!onlyNotLost) return this.slots[previousIndex];
        else {
            if (this.slots.filter((slot) => slot.state !== 'lost').length === 0)
                return undefined;

            let found = false;
            do {
                if (this.slots[previousIndex].state !== 'lost') found = true;
                else
                    previousIndex =
                        (previousIndex - 1 + this.slots.length) %
                        this.slots.length;
            } while (!found);

            return this.slots[previousIndex];
        }
    }

    public getPreviousBet(onlyNotLost?: boolean): Bet | undefined {
        const currentSlot = this.getCurrentSlot();
        if (!currentSlot) return undefined;

        const previousSlot = this.getPreviousSlot(currentSlot, onlyNotLost);
        if (!previousSlot) return undefined;

        return previousSlot.bet;
    }
}

export default RoomStore;
