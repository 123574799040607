import React from 'react';
import { ActionIcon, Flex, Group, Title } from '@mantine/core';
import { BiCurrentLocation } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import { GiCrown } from 'react-icons/gi';
import { observer } from 'mobx-react-lite';

import { SlotState } from '../../../engine/model';

interface SlotTitleProps {
    name: string;
    state: SlotState;
    disbandCallback?: () => Promise<void>;
}

const SlotTitle: React.FC<SlotTitleProps> = ({
    name,
    state,
    disbandCallback,
}) => {
    return (
        <Group position='apart'>
            <Title order={3} color={state === 'lost' ? 'dimmed' : undefined}>
                <Flex justify='flex-start' align='center' gap='sm'>
                    {(state === 'current' || state === 'dropping') && (
                        <BiCurrentLocation />
                    )}
                    {state === 'won' && <GiCrown />}
                    <>{name}</>
                </Flex>
            </Title>
            {disbandCallback && (
                <ActionIcon
                    variant='light'
                    color='red'
                    onClick={disbandCallback}
                >
                    <BsTrash />
                </ActionIcon>
            )}
        </Group>
    );
};

export default observer(SlotTitle);
