import React from 'react';
import { Group, Title } from '@mantine/core';
import { GiPerspectiveDiceSixFacesRandom } from 'react-icons/gi';
import { observer } from 'mobx-react-lite';

import Rules from './Rules';
import AppVersion from './AppVersion';

const AppHeader: React.FC = () => {
    return (
        <Group position='apart'>
            <Group align='start' spacing='xs'>
                <Title>
                    <Group spacing='xs'>
                        <GiPerspectiveDiceSixFacesRandom />
                        Maputa!
                    </Group>
                </Title>
                <AppVersion />
            </Group>
            <Rules />
        </Group>
    );
};

export default observer(AppHeader);
