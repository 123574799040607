import { sendCommand } from './client';
import { Bet } from '../../../engine/model';

/**
 * Enter a room as a spectator.
 * @param pin Room PIN.
 */
export function enter(pin: string) {
    sendCommand('enter', { pin });
}

/**
 * Leave room.
 */
export function leave() {
    sendCommand('leave');
}

/**
 * Take a seat.
 */
export function takeSeat() {
    sendCommand('take-seat');
}

/**
 * Leave the seat.
 */
export function leaveSeat() {
    sendCommand('leave-seat');
}

/**
 * Add bot to the room.
 */
export function addBot() {
    sendCommand('add-bot');
}

/**
 * Kick a player from the room.
 * @param targetId ID of the player to kick.
 */
export function kick(targetId: string) {
    sendCommand('kick', { targetId });
}

/**
 * Start a new game or round.
 */
export function start() {
    sendCommand('start');
}

/**
 * Place a bet.
 * @param bet Desired bet.
 */
export function bet(bet: Bet) {
    sendCommand('bet', { bet });
}

/**
 * Check previous player's bet.
 */
export function check() {
    sendCommand('check');
}

/**
 * Drops one of player dice as a result of check.
 */
export function dropDie() {
    sendCommand('drop-die');
}

/**
 * Forcefully get room state information.
 */
export function getInfo() {
    sendCommand('get-info');
}
