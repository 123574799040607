import React, { ChangeEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Image,
    Button,
    Card,
    Grid,
    Group,
    Stack,
    Text,
    TextInput,
    Title,
    Loader,
} from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStore } from '../stores/stores';
import * as _network from '../network/network';
import PageLayout from './PageLayout';
import notify from '../utils/notifications';
import bg from './ship.jpg';

const LoginPage: React.FC = () => {
    const { userStore } = useStore();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [name, setName] = useState(userStore.name);
    const [pin, setPin] = useState('');
    const { t } = useTranslation();

    const handleName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setName(value);
    }, []);

    const handlePin = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setPin(value);
    }, []);

    const createRoom = useCallback(async () => {
        if (name) {
            setLoading(true);
            try {
                const user = await _network.signin(name);
                userStore.id = user.id;
                userStore.name = user.name;

                const pin = await _network.createRoom();

                navigate(`room/${pin.toLowerCase()}`);
            } catch (e) {
                notify(t('error_create_room'), (e as Error).message, true);
                console.error(e);
            }
            setLoading(false);
        }
    }, [name, navigate, t, userStore]);

    const joinRoom = useCallback(async () => {
        if (pin && name) {
            setLoading(true);
            try {
                const user = await _network.signin(name);
                userStore.id = user.id;
                userStore.name = user.name;

                navigate(`room/${pin.toLowerCase()}`);
            } catch (e) {
                notify(t('error_join_room'), (e as Error).message, true);
                console.error(e);
            }
            setLoading(false);
        }
    }, [name, navigate, pin, t, userStore]);

    return (
        <PageLayout>
            <Grid align='stretch'>
                <Grid.Col lg={7} md={12}>
                    <Card>
                        <Stack>
                            <Image src={bg} radius='sm' height={300} />
                            <Title order={2}>{t('description_title')}</Title>
                            <Text>{t('description')}</Text>
                        </Stack>
                    </Card>
                </Grid.Col>
                <Grid.Col span='auto'>
                    <Stack>
                        <Card>
                            <Stack>
                                <Text>{t('user_name_prompt_description')}</Text>
                                <TextInput
                                    w={300}
                                    label={t('user_name_prompt')}
                                    placeholder={
                                        t('user_name_prompt_placeholder') || ''
                                    }
                                    value={name}
                                    onChange={handleName}
                                />
                            </Stack>
                        </Card>
                        <Card>
                            <Stack>
                                <Text>{t('pin_prompt_description')}</Text>
                                <Group>
                                    <TextInput
                                        w={300}
                                        placeholder={t('pin_prompt') || ''}
                                        value={pin}
                                        onChange={handlePin}
                                    />
                                    <Button
                                        // w={150}
                                        disabled={!name || !pin || isLoading}
                                        onClick={joinRoom}
                                    >
                                        {!isLoading ? (
                                            t('pin_prompt_confirm')
                                        ) : (
                                            <Loader size='sm' />
                                        )}
                                    </Button>
                                </Group>
                            </Stack>
                        </Card>
                        <Card>
                            <Group>
                                <Text w={300}>{t('new_room_description')}</Text>
                                <Button
                                    // w={150}
                                    disabled={!name || isLoading}
                                    onClick={createRoom}
                                >
                                    {!isLoading ? (
                                        t('new_room_confirm')
                                    ) : (
                                        <Loader size='sm' />
                                    )}
                                </Button>
                            </Group>
                        </Card>
                    </Stack>
                </Grid.Col>
            </Grid>
        </PageLayout>
    );
};

export default observer(LoginPage);
