export const env = process.env.NODE_ENV as string;

if (!env) throw new Error('No NODE_ENV set. Check .env');

let BASE_URL = process.env.REACT_APP_URL || 'http://localhost';

if (process.env.NODE_ENV === 'development')
    BASE_URL = `${BASE_URL}:${process.env.REACT_APP_BACKEND_PORT || '8080'}`;

export const REST_URL = `${BASE_URL}/api`;
export const WS_URL = `${BASE_URL}`;
