import React from 'react';
import {
    ActionIcon,
    ActionIconVariant,
    MantineColor,
    Tooltip,
} from '@mantine/core';
import { observer } from 'mobx-react-lite';

interface TooltipButtonProps {
    label: string;
    icon: React.ReactNode;
    color?: MantineColor;
    variant?: ActionIconVariant;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const TooltipButton: React.FC<TooltipButtonProps> = ({
    label,
    icon,
    color,
    variant,
    disabled,
    onClick,
}) => {
    return (
        <Tooltip label={label} disabled={disabled}>
            <ActionIcon
                variant={variant || 'default'}
                disabled={disabled}
                onClick={onClick}
                color={color}
            >
                {icon}
            </ActionIcon>
        </Tooltip>
    );
};

export default observer(TooltipButton);
