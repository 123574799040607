import React from 'react';
import { Center, Text } from '@mantine/core';
import {
    GiDiceSixFacesOne,
    GiDiceSixFacesTwo,
    GiDiceSixFacesThree,
    GiDiceSixFacesFour,
    GiDiceSixFacesFive,
    GiDiceSixFacesSix,
    GiPerspectiveDiceSixFacesRandom,
} from 'react-icons/gi';
import { observer } from 'mobx-react-lite';

interface DieProps {
    value: number;
}

const DieIcon: React.FC<DieProps> = ({ value }) => {
    switch (value) {
        case 1:
            return (
                <Center>
                    <GiDiceSixFacesOne />
                </Center>
            );
        case 2:
            return (
                <Center>
                    <GiDiceSixFacesTwo />
                </Center>
            );
        case 3:
            return (
                <Center>
                    <GiDiceSixFacesThree />
                </Center>
            );
        case 4:
            return (
                <Center>
                    <GiDiceSixFacesFour />
                </Center>
            );
        case 5:
            return (
                <Center>
                    <GiDiceSixFacesFive />
                </Center>
            );
        case 6:
            return (
                <Center>
                    <GiDiceSixFacesSix />
                </Center>
            );
        default:
            return (
                <Center>
                    <GiPerspectiveDiceSixFacesRandom />
                </Center>
            );
    }
};

const Die: React.FC<DieProps> = ({ value }) => {
    return (
        <Text fz='xl'>
            <DieIcon value={value} />
        </Text>
    );
};

export default observer(Die);
