import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Divider, Group, Title, Text } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import {
    GiRobotAntennas,
    GiPlayButton,
    GiWoodenChair,
    GiExitDoor,
} from 'react-icons/gi';
import { BsClipboard, BsClipboardCheck } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

import TooltipButton from '../TooltipButton';
import { useStore } from '../../stores/stores';
import * as _network from '../../network/network';
import Die from './slot/Die';

const RoomPageControls: React.FC = () => {
    const navigate = useNavigate();
    const { userStore, roomStore, chatStore } = useStore();
    const clipboard = useClipboard({ timeout: 1000 });
    const [state, setState] = useState<{
        isHost: boolean;
        canAddBot: boolean;
        canStart: boolean;
        canTakeSlot: boolean;
    }>({
        isHost: false,
        canAddBot: false,
        canStart: false,
        canTakeSlot: false,
    });
    const { t } = useTranslation();

    const leave = useCallback(() => {
        _network.leave();
        roomStore.erase();
        chatStore.clear();
        navigate('/');
    }, [chatStore, navigate, roomStore]);

    const takeSlot = useCallback(async () => {
        _network.takeSeat();
    }, []);

    const addBot = useCallback(async () => {
        _network.addBot();
    }, []);

    const start = useCallback(async () => {
        _network.start();
    }, []);

    useEffect(() => {
        if (!roomStore.pin || !roomStore.slots || !userStore.id) return;

        const mySlot = roomStore.getSlot(userStore.id);
        const isHost = (mySlot && mySlot.isHost) || false;
        const canAddBot = isHost && roomStore.state === 'LobbyState';
        const canStart =
            isHost &&
            roomStore.state === 'LobbyState' &&
            roomStore.getPlayerSlots().length > 1;
        const canTakeSlot =
            roomStore.state === 'LobbyState' && mySlot?.state === 'spectator';

        setState((prev) => {
            return {
                ...prev,
                isHost,
                canAddBot,
                canStart,
                canTakeSlot,
            };
        });
    }, [roomStore, roomStore.pin, roomStore.slots, userStore.id]);

    if (!roomStore.pin || !userStore.id) return <></>;

    return (
        <Group position='apart'>
            <Group spacing='sm'>
                <Title
                    order={2}
                    sx={(theme) => ({
                        color: roomStore.isMaputa
                            ? theme.colors.blue[5]
                            : undefined,
                    })}
                >
                    {t('room_title') + roomStore.pin}
                </Title>
                <TooltipButton
                    variant='outline'
                    label={t('room_copy_pin')}
                    icon={
                        !clipboard.copied ? (
                            <BsClipboard />
                        ) : (
                            <BsClipboardCheck />
                        )
                    }
                    color={
                        !clipboard.copied
                            ? roomStore.isMaputa
                                ? 'blue'
                                : undefined
                            : 'green'
                    }
                    onClick={() => clipboard.copy(roomStore.pin)}
                />
            </Group>
            {roomStore.isMaputa && (
                <Group
                    spacing='sm'
                    sx={(theme) => ({
                        color: theme.colors.blue[5],
                    })}
                >
                    <Die value={-1} />
                    <Text>{t('room_maputa_alert')}</Text>
                    <Die value={-1} />
                </Group>
            )}
            <Group spacing='sm'>
                {state.isHost && (
                    <>
                        <Group>
                            <TooltipButton
                                label={t('room_add_bot')}
                                variant='outline'
                                disabled={!state.canAddBot}
                                onClick={addBot}
                                icon={<GiRobotAntennas />}
                            />
                            <TooltipButton
                                label={t('room_start_game')}
                                variant='outline'
                                color='blue'
                                disabled={!state.canStart}
                                onClick={start}
                                icon={<GiPlayButton />}
                            />
                        </Group>
                        <Divider orientation='vertical' />
                    </>
                )}
                <TooltipButton
                    label={t('room_leave')}
                    variant='outline'
                    color='red'
                    onClick={leave}
                    icon={<GiExitDoor />}
                />
                <TooltipButton
                    label={t('room_take_seat')}
                    variant='filled'
                    color='blue'
                    disabled={!state.canTakeSlot}
                    onClick={takeSlot}
                    icon={<GiWoodenChair />}
                />
            </Group>
        </Group>
    );
};

export default observer(RoomPageControls);
