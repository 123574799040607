import React from 'react';

import UserStore from './UserStore';
import RoomStore from './RoomStore';
import ChatStore from './ChatStore';

export const stores = {
    userStore: new UserStore(),
    roomStore: new RoomStore(),
    chatStore: new ChatStore(),
};

export const StoreContext = React.createContext(stores);

export const useStore = () => {
    const store = React.useContext(StoreContext);
    if (!store) {
        throw new Error('useStore must be used within a StoreProvider.');
    }
    return store;
};
