import React from 'react';
import { AppShell, Center, Header, Loader } from '@mantine/core';
import { observer } from 'mobx-react-lite';

import AppHeader from './AppHeader';

interface PageLayoutProps {
    children: React.ReactNode;
    isLoading?: boolean;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children, isLoading }) => {
    return (
        <AppShell
            padding='md'
            header={
                <Header height={60} p='xs'>
                    <AppHeader />
                </Header>
            }
        >
            {isLoading ? (
                <Center>
                    <Loader />
                </Center>
            ) : (
                children
            )}
        </AppShell>
    );
};

export default observer(PageLayout);
