import restClient from './client';
import { User } from '../../../engine/model';

type ResponseMessage = {
    success: boolean;
    payload?: unknown;
};

/**
 * Sign in user with provided name.
 * @param name User name.
 * @returns Object representing logged in user.
 */
export async function signin(name: string): Promise<User> {
    const { data } = await restClient.post('/user/signin', { name: name });
    return data.payload;
}

/**
 * Create a new room.
 * @returns PIN of newly created room.
 */
export async function createRoom(): Promise<string> {
    const { data } = await restClient.post<ResponseMessage>('/room/create');
    return (data.payload as { pin: string }).pin;
}
